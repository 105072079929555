.key-value-table{

}

.key-value-table tr{
    border-bottom: 1px solid var(--bs-primary);
}
.key-value-table{

}

.small-image{
    width:100px;
}