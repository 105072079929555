.date-card{
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
}
.date-card.active, 
.date-card:hover{
    background-color: var(--bs-primary);
}
.date-row{
    display: flex;
    text-wrap: nowrap;
    overflow: auto;
}
.date-availability{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5%;
    text-align: center;

}

.date-availability .radio-card.unavailable{
    background-color: rgb(255, 123, 123);
    
}
.date-availability .radio-card.unavailable:hover{
    cursor: not-allowed;
}

.time-availability{
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 4px;
    margin-bottom: 4px; 
}


.date-availability .radio-wrapper{
    display: grid !important;
    grid-template-columns: repeat(4, 25%);
    align-items: center;
}

@media (max-width: 764px){
    .date-availability #wrapper-productlistings  .radio-wrapper{
        grid-template-columns: repeat(2, 50%);
    }
}
.date-availability .radio-card{
    padding: 6px 24px;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 8px;
}
.date-availability .radio-card:hover, 
.date-availability .radio-card:focus, 
.date-availability .radio-card:has(input:checked) {
    padding: 6px 24px 6px 19px;
}