html {
    scroll-behavior: smooth;
  }
.store-row{
    display:grid;
    grid-template-columns: 50% 50%;
    font-family: 'Roboto Slab',serif;
}
.filter-pane{
}

.product-detail-title{
    color: #000 ;
    font-size: 1.85rem !important;
    margin: 0 !important;
}
.vat-message{
    font-size: 12px;
}
.u-repeater .product-card{
    width: 100% !important;
    max-width: 100% !important;
}

.price-tag{
    display: inline;
    margin-right: .5rem;
    margin-bottom: .5rem;
}
.products-nav{
    padding: .5rem
}
.products-nav .breadcrumb-item {
    font-size: 12px;
}
.cart-button{
    padding: 10px 20xp
}
.product-form{
    margin-top: .5rem;
    
    .option{
        max-width: 100%;
        padding: 0;
        position: relative;
    }
    .option:has(input:checked):after {
        bottom: -10px;
        left: -15px;
        content: "✓";
        position: absolute;
        background-color: white;
        border-radius: 50%;
        padding: 4px 10px;
        border: 1px solid var(--bs-border-color);
    }
    .btn-group{
        width:fit-content
    }
    input[type=number] {
        width: 48px;
        padding: 4px!important;
    }
    .btn-group-lg input[type=number] {
        width: 75px !important;
        padding: 4px 8px 4px 0 !important;
    }

    .option-image{
        max-width: 100%;
    }
    .option-description {
        flex-grow: 1;
        padding: .5rem .25rem;
    }
    .radio-card:has(input:checked) {
        border-left: 5px solid var(--bs-primary);
    }
    .radio-card:hover {
        border-left: 5px solid var(--bs-dark) !important;
    }

    .radio-card{
        margin: 5px 10px;
        height: 90%;
        width:fit-content;
        border: 1px solid var(--bs-border-color);
    }

    .radio-options{
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
        align-items: end;
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
        margin-bottom: .75rem;
        padding-bottom: .5rem;
    }

    .label-group input[type=radio], .label-group input[type=checkbox] {
        display: none;
    }
}

.product-card{
    max-width: 100%;
}


.main-image.placeholder{
    background-color: var(--bs-light);
}

.category-list{
    display: flex;
    align-items: start;
    overflow: auto;
    z-index: 1;
}

/* width */
.category-list::-webkit-scrollbar {
    width: 4px;
    height: 4px;

}

/* Track */
.category-list::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.category-list::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.category-list::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.category-card{
    width: 250px;
    margin-right: 8px;
    position: relative;
}
.category-image{
    height: 100%;
    position: relative;
    width: 166px;
    border: 1px solid var(--bs-border-color);
}
.category-description{
    position: absolute;
    background-color: #00000033;
    bottom: 2px;
    padding:0 4px;
}
.category-title{
    font-size: 10px;
    margin:0;
    color: white;
}
.image-wrapper{
    max-height: 100%;
}

@media (max-width: $md) {
    .store-row{
        grid-template-columns: 50% 50%;
    }
    .product-form{
        .radio-options{
            grid-template-columns: 50% 50%;

        }
    }
}

@media (min-width: 768px) {
    .store-row{
        grid-template-columns: 50% 50%;
    }
}

@media (min-width: 992px) {
    .store-row{
        grid-template-columns: 33% 33% 33%;
    }
}

@media (min-width: 1200px) {
    .store-row{
        grid-template-columns: 25% 25% 25% 25%;
    }
}

.product-card:hover{
    transform: scale(1.03);

}
.product-card-inner{
    height: 97%;;
}
.product-image{
    margin-bottom: 1rem;
    height: 100%;
    width: 100%;
    position: relative;
}
.image-wrapper{
    aspect-ratio: 16 / 12;
}
.main-image{
    position: absolute;
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s, opacity 0.5s linear;
}
.hover-image{
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s linear;
}
.product-image:hover .main-image{
    visibility: hidden;
    opacity: 0;
}
.product-image:hover .hover-image{
    visibility: visible;
    opacity: 1;
}

.product-description{
    padding: 0 1rem;
}
.product-title{
    font-weight: 600;
    margin:0;
}
.hover:hover{
    text-decoration:underline;
}
.hover:hover{
    text-decoration:underline;
}
.product-price{
    display: flex;
    flex-direction:column;
    align-items:start;
}
.product-tags {
    margin-top: 5%;
    position: absolute;
    z-index: 1;
}

.product-unavailable{
    font-size: 12px;
    color: white;
    padding: 5px;
    position: relative;
    --bs-bg-opacity: .5;

}

.page-item.active .page-link{
    background-color: var(--bs-primary) !important;
    color:white !important;
    border-color: var(--bs-primary);
}
.page-item .page-link{
    color: var(--bs-primary) !important;
}

div.u-text-variant {
    min-height: 50px;
}
/* add discount tag*/
.discount-group{
    margin-top: 5%;
    position: absolute;
    z-index:5;
    right: 0;
}
.discount-tag{
    font-size: 12px;
    color: white;
    background-color: #4B0F10;
    padding: 5px;
    position: relative;
}
.discount-label{
    color: white;
    background-color: #4B0F10;
    padding: 10px 18px;
    z-index: 1;
    margin-left: 0%;
  
}
.discount-text{
    color: #4B0F10;
    margin:0px !important;
    font-size: 14px;
}
  
  
  
.old-price{
    text-decoration: line-through solid #4D4D4D !important;
    -webkit-text-decoration-line: line-through;
    -webkit-text-decoration-color: #4D4D4D;
    line-height: 1;
    font-size: 12px;
    color:#4D4D4D;
}

.pricex{
    display:inline;
}
  
.option-discount-tag{
    display: inline;
    background-color: #04df4366;
    padding: 0px 15px;
    margin-left: 5px;
    border-radius: 25px;
    width: auto;
}
  
.regular-price{
  font-size: 13px;
  text-decoration: line-through;
  -webkit-text-decoration-line: line-through;
  -webkit-text-decoration-color: red;

}
.variant-image{
    width: 100px;
}
.quantity-option{
    margin-bottom: .75rem;
    padding-bottom: .5rem;
    display: flex;
    align-items: end;
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    
}
.quantity-option-form-element{
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    flex-grow:1;

}
.quantity-description{
    font-size: 0.875rem;
}
.option-description{
    font-size: 0.75rem;
    min-height:40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.quantity-input{
    font-size: 14px;
    width: 55px;
    padding: 0.175rem 0.55rem;
    background-color: transparent;
}

.product-list-item {
    list-style: none;
  }
  
.product-list-item li:before {
    content: '✓';
    margin-right: 8px;
}

.accordion-header{
    margin:0 !important;
}

.configure{
    font-size: 12px;;
    display:flex;
    align-items: center;
}

.configure::after{
    content:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    width: 12px;
    height: 12px;
    display:block;
}


// .u-layout-col > .u-size-30 {
//     flex: 0 0 50% !important;
// }

label.btn{
    border: 0;
    background-color: white;

}

select{
    background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 50%;
    background-size: 12px 12px;

}


// .u-section-1 .u-group-1 {
//     width: 550px;
// }


.detail-page-wrapper {
    display: grid;
    grid-template-columns: 60% 40%;

}
.product-image-cell{
    padding-top: 1rem;
}

.product-carousel{
    height:100%;
}

.product-form-cell{
    grid-column: 2;
    grid-row-start: 1;
    grid-row-end: span 2;
    position: sticky;
    top: 200px;
    padding: 1rem;
    background-color: var(--bs-light);
}

.product-information{
    position: sticky;
    top: 150px;
}
@media (max-width: $md){
    .detail-page-wrapper {
        display: grid;
        grid-template-columns: 100%;
    }
    .product-image-cell{
        padding-top: 0rem;
    }

    .product-carousel{
        height: 95%;
    }
    
    .product-form-cell{
        top:0;
        position: relative;
        grid-column: 1;
        grid-row-start: 2;
        padding: 1rem 2rem;
    
    }
    .product-information.cell{
    
    }
}




@media (max-width: 575px){
    .u-form-1{
        width: 364px !important; 
        margin-left: 14px !important;
        margin-right: 14px !important; 
    }
}

.label-group input[type="radio"],
.label-group input[type="checkbox"]{
    display:none;
}
.share-whatsapp, bi-whatsapp {
    font-size: 27px;
    width: 100%;
    height: 100%;
}
.share-whatsapp:hover, bi-whatsapp {
    color:#25d366 !important;
}


