/* Variables */
$primary: #3eaa3b;
$secondary:#000000;
$danger: #ff0000;
$success: #12e012;
$info: #0dcaf0;
$warning: #ffc107;
$light: #f8f9fa;
$dark: #181d20;

body{
    font-family: Oswald,sans-serif;
}


/* Forms  */
/*$input-btn-padding-y:         0;*/
$input-btn-font-size:         14px;

/* Buttons */

$border-radius: 4px;
$btn-border-radius:           $border-radius;
$input-border-radius:         $border-radius;
$border-radius-sm:            $border-radius;
$border-radius-lg:            $border-radius;
$border-radius-xl:            $border-radius;
$border-radius-2xl:           $border-radius;
$border-radius-pill:          50rem;

/* Modal */
$modal-header-border-color: #dee2e678;
$modal-header-border-width: 1px;


/*Tooltip*/
$tooltip-color:                     white;
$tooltip-bg:                        $primary;
$tooltip-border-radius:             $border-radius;
