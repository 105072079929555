a{
    text-decoration: none !important;
}

.alert{
    font-size: 14px;
    padding: .5rem 1rem;
    display: flex;
    align-items: center;
}

.u-layout-cell .u-social-whatsapp:hover{
color: #25D366!important;
}

.u-container-style:hover .u-icon:hover{
color: #4D4D4D !important;
}

.contact-socials {
    list-style-type: none;

}
